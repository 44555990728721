import React from "react";
import { useNavigate } from "react-router-dom";
import PungoButton from "../PungoButton";
import colors from "../../styles/export.module.scss";
import { useTranslation, Trans } from "react-i18next";
import classnames from "classnames";
import styles from "./index.module.scss";

interface PungoServiceCardProps {
  title: string;
  children?: React.ReactNode;
  image: string;
  color?: string;
  className?: string;
  link : string;
}


const PungoServiceCard: React.FC<PungoServiceCardProps> = (props) => {
  const { color = colors.green4, title, image, children, className, link } = props;
  const getClassnames = () => classnames(styles.container, className);
  const { t } = useTranslation();
  const navigate = useNavigate();

  let URL = "";

  const handleSubmit = () => {
    switch (link){
      case "analytics":
        URL="https://marketing.pungoapp.com/industrial-optimizer";
        break;
      case "ai":
        URL = "https://marketing.pungoapp.com/pump-failure-prediction";
        
        break;
      case "sustainability":
        URL = "https://marketing.pungoapp.com/energy-management";
        break;
      default:
        navigate(`/*`);
    }

    if (URL != ""){
      window.open(URL, '_blank', 'noreferrer');
    }
  };


  return (
    <div className={getClassnames()} style={{ borderColor: `${color}` }}>
      <img src={image} className={styles.image} alt="serviceLogo" />
      <div className={styles.title} style={{ color }}>
        {title}
      </div>
      {children}
      <div className={styles.button}>
        <PungoButton label={`${t("productsContent.button")}`} color={color === "#007297" ? "primary" : color === "#8ec14b" ? "secondary" : "tertiary" } onClick={handleSubmit} classNames={styles.submitButtonOver} />
      </div>
    </div>
  );
};

export default PungoServiceCard;
